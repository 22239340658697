import Link from "next/link";
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Layout from "./layout";
import LoginModal from "./auth/LoginModal";

const Error = ({ statusCode, state }) => {
  const message = {
    error404: "Page Not Found",
    error401: "Sorry, you don't have permission to view this page.",
    error403: "Forbidden",
    error500: "Sorry, It's not you. It's us",
  }[`error${statusCode}`];
  return (
    <Layout title={`Stride Equity | ${message}`} state={state} invertNav>
      <div
        className="bg2"
        style={{ marginBottom: "-220px", paddingTop: "120px" }}
      >
        <Container className="content">
          <Row>
            <Col />
            <Col>
              <h2 className="mb-3">{message}</h2>
              {statusCode == 404 ? (
                <p className="mt-5 lead">
                  We could not find what you are looking for
                  <br />
                  <br />
                  <Link href="/">
                    <Button variant="outline-light">Back to home</Button>
                  </Link>
                </p>
              ) : null}
              {statusCode == 401 ? (
                <div>
                  <p className="lead">Please login to continue.</p>
                  <Button
                    variant="outline-light"
                    size="lg"
                    onClick={() => LoginModal.show()}
                  >
                    Login
                  </Button>
                </div>
              ) : null}
              {statusCode == 403 ? (
                <div>
                  <p className="lead">Oops you are not supposed to be here</p>
                  <Link href="/">
                    <Button variant="outline-light">Back to home</Button>
                  </Link>
                </div>
              ) : null}
              {statusCode == 500 ? (
                <div>
                  <p className="lead">
                    We are experiencing an internal server problem
                  </p>
                  <p className="lead"> Please try again later</p>
                  <Link href="/">
                    <Button variant="outline-light">Back to home</Button>
                  </Link>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default Error;
