import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Badge,
  Form,
  FormControl,
  InputGroup,
  Navbar,
  Row,
} from "react-bootstrap";
import { useRouter } from "next/router";
import { useS3Upload } from "next-s3-upload";
import ColorField from "../ColorField";
import imageResizer, { dataURIToFile } from "../../utils/imageResizer";
import Colors from "../../utils/colorPaletteExtracter";
import Access from "../../utils/access";
import Bg from "../../utils/imageBackgrounds";
import Editable from "../Editable";
import EditContentField from "../EditContentField";
import IconButton from "../IconButton";
import Layout from "../layout";
import UploadAndCrop from "../UploadAndCrop";
import Global from "../../utils/globalEvents";
import Api from "../../utils/apiCall";
import Status from "../Status";
import SlugField from "../SlugField";
import chroma from "chroma-js";
import { SmallStatusAction } from "../../utils/constant";

const ContentPage = ({
  state,
  page = { name: "New Page", description: "Your description" },
  data,
}) => {
  const [mode, setMode] = useState(page.id ? false : "edit");
  const [pageData, setPageData] = useState({});
  const { uploadToS3 } = useS3Upload();
  const router = useRouter();
  let backupData = { ...page };
  const { user } = state;

  useEffect(() => {
    setPageData(page);
  }, [page]);

  const canEdit =
    user.id && (Access.can("manage", user) || page.UserId === user.id);

  const background = () => {
    const { coverImage, colors } = pageData;
    if (!coverImage) return colors?.theme === "dark" ? "#000" : "#fff";
    const imageUrl = coverImage.image;
    const p = colors.palette;
    if (colors.theme === "light") {
      const c1 = chroma(p[4]).alpha(0.6);
      const c2 = chroma(p[3]).alpha(0.5);
      return `linear-gradient(180deg, ${c1} 10%, ${c2} 80%), url(${imageUrl}) center center / cover, ${colors.imgColor}`;
    }
    const c1 = chroma(p[0]).alpha(0.6);
    const c2 = chroma(p[1]).alpha(0.5);
    return `linear-gradient(180deg, ${c1} 10%, ${c2} 80%), url(${imageUrl}) center center / cover, ${colors.imgColor}`;
  };

  const save = async () => {
    const result = await Api.call("page", "POST", pageData);
    setMode(false);
    Global.dispatch("editmodeHide", false);
    if (!backupData.id) router.push(`/page/${result.id}`);
    backupData = { ...pageData };
  };
  const cancel = () => {
    setPageData(backupData);
    Global.dispatch("editmodeHide", false);
    setMode(false);
  };
  const edit = () => {
    Global.dispatch("editmodeHide", true);
    setMode("edit");
  };
  const saveImage = async (imgData, imgElement) => {
    Global.setLoading(true);
    const file = dataURIToFile(imgData);
    console.log(imgData);
    const imageFile = await imageResizer(file, {
      width: 1600,
      height: 900,
      filename: "page.jpg",
    });
    const image = (await uploadToS3(imageFile)).url;
    const thumbFile = await imageResizer(imageFile, {
      width: 640,
      height: 360,
      filename: "page-thumb.jpg",
    });
    const thumb = (await uploadToS3(thumbFile)).url;
    const colors = Colors.extract(imgElement);
    const update = {
      coverImage: { image, thumb },
      colors,
    };
    setPageData({ ...pageData, ...update });
    Global.setLoading(false);
  };

  const updateFields = (data) => {
    console.log("fields update", data);
    setPageData({ ...pageData, ...data });
  };

  const theme = () => (pageData.colors?.theme === "dark" ? "dark" : "light");

  // const headerStyle = pageData.coverImage
  //   ? {
  //     height: '56.25vw',
  //     maxHeight: "550px",
  //     display: "flex",
  //     background: background(),
  //   }
  //   : {
  //     height: "56.25vw",
  //     maxHeight: "350px",
  //     display: "flex",
  //     paddingTop: "150px",
  //   };

  const headerStyle = pageData.coverImage
    ? {
        height: "auto",
        maxHeight: "560",
        display: "flex",
        background: background(),
      }
    : {
        height: "auto",
        maxHeight: "auto",
        display: "flex",
        paddingTop: "auto",
      };

  return (
    <Layout
      title={
        page.slug === "faq"
          ? "Stride Equity - FAQ"
          : page.slug === "webterms"
          ? "Stride Equity - Website Terms and Conditions"
          : page.slug === "investor-terms-and-conditions"
          ? "Stride Equity - Investor Terms and Conditions"
          : page.slug === "privacy" && "Stride Equity - Privacy Policy"
      }
      meta={{
        card: "summary_large_image",
        description:
          page.slug === "faq"
            ? "Read the most common questions and answers about how equity crowdfunding works with Stride Equity."
            : page.slug === "webterms"
            ? "These Terms of Use (Terms of Use) apply to any person (you or your) accessing or using the website operated by StrideCorp Equity Partners Pty Ltd (Stride Equity, we, us, or our) at www.Strideequity.com.au (Website)."
            : page.slug === "investor-terms-and-conditions"
            ? "StrideCorp Equity Partners Pty Ltd (ABN 49 607 216 928, AFSL No. 482 668) (“Stride Equity”) operates a crowd sourced funding platform (“the Platform”), through which unlisted Australian companies (“the Issuer”) can offer its securities that investors can apply for (“Offer”)."
            : page.slug === "privacy" &&
              "StrideCorp Equity Partners Pty Ltd (ACN 607 216 928) (“Stride Equity”) is committed to safeguarding the personal information that we receive.",
      }}
      state={state}
      invertNav={theme() === "dark"}
    >
      {canEdit ? (
        <Navbar
          className="save-bar bottom-nav-bar"
          fixed="bottom"
          variant="dark"
        >
          <Container className="text-right">
            {mode === "edit" ? (
              <>
                <IconButton icon="cancel" onClick={cancel}>
                  Cancel
                </IconButton>{" "}
                &nbsp;
                <div>
                  <Status.Select
                    statusList={Object.values(SmallStatusAction)}
                    selected={pageData.status}
                    onChange={(status) => updateFields({ status })}
                  />
                  &nbsp;
                  <IconButton variant="light" icon="check" onClick={save}>
                    Save
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <Navbar.Text>
                  <Badge>You can edit this page</Badge>
                </Navbar.Text>
                <div className="justify-content-end">
                  <IconButton variant="light" onClick={edit}>
                    Edit
                  </IconButton>
                </div>
              </>
            )}
          </Container>
        </Navbar>
      ) : null}
      <div
        style={headerStyle}
        className={`${
          pageData.coverImage ? "image-header" : ""
        } image-header-${theme()}`}
      >
        <div style={{ alignSelf: "center", width: "100%" }}>
          {mode === "edit" ? (
            <div style={{ margin: "100px auto auto 80px" }}>
              <UploadAndCrop
                size="lg"
                variant="dark"
                aspectRatio={16 / 9}
                update={saveImage}
                label="upload cover"
              />
              &nbsp;
              {pageData.coverImage ? (
                <IconButton
                  variant="dark"
                  size="lg"
                  onClick={() =>
                    updateFields({ coverImage: false, colors: false })
                  }
                  icon="cancel"
                >
                  Remove picture
                </IconButton>
              ) : null}
              <br />
              {pageData.colors
                ? pageData.colors.palette.map((color, index) => (
                    <ColorField
                      key={index}
                      value={color}
                      placement="bottom"
                      onChange={(c) => {
                        const palette = [...pageData.colors.palette];
                        palette.splice(index, 1, c);
                        updateFields({
                          colors: { ...pageData.colors, palette },
                        });
                      }}
                    />
                  ))
                : null}
            </div>
          ) : null}
          <Container>
            <div className="editable-page service-page">
              <h1 className="webterm-title mb-3">
                <Editable
                  options={{ maxChars: 100 }}
                  edit={mode === "edit"}
                  value={pageData.name}
                  update={(name) => updateFields({ name })}
                />
              </h1>
              <hr />
              <div className="service-content-wrapper">
                {mode === "edit" ? (
                  <SlugField
                    slug={pageData.slug}
                    update={(slug) => updateFields({ slug })}
                  />
                ) : null}
                <Row>
                  <Col>
                    <div className="effectiveby">
                      <Editable
                        options={{ maxChars: 300 }}
                        edit={mode === "edit"}
                        value={pageData.description}
                        update={(description) => updateFields({ description })}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Container className="page-content service-page pt-0">
        <Row className="service-content-wrapper">
          {/* <Col md={{ span: 6, offset: 3 }}> */}
          <Col>
            {mode === "edit" ? (
              <EditContentField
                options="p b h2 h3 li img left center right a"
                content={pageData.content}
                update={(content) =>
                  setPageData({ ...pageData, ...{ content } })
                }
              />
            ) : null}
            {mode !== "edit" ? (
              <div
                className="editor-content editor-pages"
                dangerouslySetInnerHTML={{ __html: pageData.content }}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ContentPage;
